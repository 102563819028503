import React from 'react'
// import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'

const policy = () => {
  return (
    <Layout>
      <div className="inner">
        <div className="common-header">
          <h1>情報セキュリティ方針</h1>
          <p className="subtitle">Security Policy</p>
        </div>
        {/* /.common-header */}

        <h2>基本理念</h2>
        <p>
          株式会社AlgoNaut（以下、当社）は、未来のソフトウエアを形にすることをミッションに掲げており、当社の事業の中で取り扱う、お客様の貴重な情報をはじめとする情報資産は、当社の経営基盤として極めて重要なものです。
          当社は、漏洩、き損、滅失等のリスクからこれら情報資産を保護することの重要性を認識し、役員、従業員以下、すべての従業者を挙げて本方針を遵守し、情報資産の機密性、完全性、可用性といった情報セキュリティを維持するための活動を実践いたします。
        </p>

        <h2>基本方針</h2>
        <p>
          1.
          情報資産を保護するために、情報セキュリティポリシーを策定し、これに従って業務を行うとともに、情報セキュリティに関連する法令、規制その他の規範、及び、お客様との契約事項を遵守いたします。
        </p>

        <p>
          2.
          情報資産に対して存在する漏洩、き損、滅失等のリスクを分析、評価するための基準を明確にし、体系的なリスクアセスメント方法を確立するとともに、定期的にリスクアセスメントを実施いたします。また、その結果に基づき、必要かつ適切なセキュリティ対策を実施いたします。
        </p>

        <p>
          3.
          担当役員を中心とした情報セキュリティ体制を確立するとともに、情報セキュリティに関する権限および責任を明確にいたします。また、すべての従業者が、情報セキュリティの重要性を認識し、情報資産の適切な取り扱いを確実にするために、定期的に教育、訓練および啓発を行います。
        </p>

        <p>
          4.
          情報セキュリティポリシーの遵守状況及び情報資産の取扱いについて、定期的に点検及び監査を行い、発見された不備や改善項目については、速やかに是正処置を講じます。
        </p>

        <p>
          5.
          情報セキュリティ上のイベントやインシデントの発生に対する適切な処置を講じるとともに、万一それらが発生した場合に際して、あらかじめ、被害を最小限に留めるための対応手順を確立し、有事の際には、速やかに対応するとともに、適切な是正処置を講じます。また、特に、業務中断に関わるようなインシデントについては、その管理の枠組みを確立し、定期的に対応、復旧試験及び見直しを行うことにより、当社の事業継続を確実にいたします。
        </p>

        <p>
          6.
          基本理念を実現するための目標を定めた情報セキュリティマネジメントシステムを確立し、これを実行するとともに、継続的に見直し、改善を行います。
        </p>

        <p className="bottom">
          2020年11月9日 制定
          <br />
          株式会社AlgoNaut
          <br />
          代表取締役　佐野 長紀
        </p>
      </div>
      {/* /.inner */}
    </Layout>
  )
}

export default policy
